<template>
  <div class="loader-block">
    <img src="@/assets/icons/searching.gif" alt="" class="h-78">
  </div>
</template>

<script>
export default {
  name: 'VerificationEmail',
  async created () {
    const payload = {
      method: 'POST',
      url: '/api/v3/account/verify',
      body: {
        code: this.$route.params.code,
        transport: 0
      }
    };

    try {
      const response = await this.$store.dispatch('ajaxWithTokenRefresh', payload);
      this.$router.push('/?profile=settings&mail_result=success');
      console.log(response);
    } catch (error) {
      this.$router.push('/?profile=settings&mail_result=error');
      console.log(error);
    }
  }
};
</script>
